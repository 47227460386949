/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// swiper
//
// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination } from 'swiper'
// import Swiper and modules styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

// init Swiper:
const swiper = new Swiper('.swiper', {
    // configure Swiper to use modules
    modules: [Navigation, Pagination],
    direction: 'horizontal',
    loop: false,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        clickable: true,
        el: '.swiper-pagination',
    },
})

window.swiper = Swiper
// any CSS you import will output into a single css file (app.css in this case)
import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect' // needed for number_counter.js
Alpine.plugin(intersect)

import timer from './alpine-components/timer.js'
import number_counter from './alpine-components/number_counter.js'

Alpine.data('number_counter', number_counter)
Alpine.data('timer', timer)

Alpine.start()
window.Alpine = Alpine

import 'slick-carousel'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const sliders = document.querySelectorAll('.slider')
for (slider of sliders) {
    slider.slick()
}
